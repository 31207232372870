<template>
  <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M54.66 40.6663L59.94 45.9463C61.7 42.7463 62.66 39.0664 62.66 35.3064C62.66 23.2264 52.98 13.3864 40.9 13.1464C28.5 12.9864 18.34 23.3864 18.34 35.8664C18.34 41.2264 20.34 46.4263 23.86 50.5063L39.62 68.1863L51.78 55.5463L45.78 49.6263" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M52.82 42.4273C55.94 36.9873 55.22 29.9473 50.58 25.3073C46.74 21.4673 41.22 20.2673 36.42 21.7873L44.1 29.4673C44.1 31.3873 44.1 32.5073 44.1 34.5073L39.7 38.9073C37.78 38.9073 36.66 38.9073 34.66 38.9073L26.98 31.2273C25.46 36.1073 26.66 41.5473 30.5 45.3873C35.14 50.0273 42.18 50.7473 47.62 47.6273" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "IconService"
}
</script>
