<template>
  <svg class="inline-block" width="80" height="58" viewBox="0 10 80 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0801 52.32L28.1601 28.08" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M13.4397 36.88C16.0465 36.88 18.1597 34.7668 18.1597 32.16C18.1597 29.5532 16.0465 27.44 13.4397 27.44C10.8329 27.44 8.71973 29.5532 8.71973 32.16C8.71973 34.7668 10.8329 36.88 13.4397 36.88Z" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M29.1194 52.56C31.7262 52.56 33.8394 50.4468 33.8394 47.84C33.8394 45.2332 31.7262 43.12 29.1194 43.12C26.5126 43.12 24.3994 45.2332 24.3994 47.84C24.3994 50.4468 26.5126 52.56 29.1194 52.56Z" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M38.6396 28.64H43.8396C45.6796 28.64 47.5197 29.2 49.1197 30.24L58.2397 36.32C58.7197 36.64 59.1996 36.8 59.7596 36.88" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M32.5596 28.64H38.6396" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M59.7598 36.88L65.5198 37.76C67.4398 38.08 69.2798 38.96 70.3198 40.56C70.9598 41.44 71.2798 42.4 71.2798 43.44V44.08C71.2798 46.08 69.5998 47.76 67.5998 47.76H66.7998" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M37.5195 47.76H50.3995" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M29.3594 36.8L59.7594 36.88" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.7592 47.76C63.7592 50.4 61.5992 52.56 58.9592 52.56C56.3192 52.56 54.1592 50.4 54.1592 47.76" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M63.7592 47.76C63.7592 45.12 61.5992 42.96 58.9592 42.96C56.3192 42.96 54.1592 45.12 54.1592 47.76" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M40.3193 28.64V36.72" stroke="#5E0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
  </svg>

</template>

<script>
export default {
  name: "IconDamage"
}
</script>

<style scoped>

</style>