<template>
  <svg class="inline-block" width="50" height="58" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5994 18.64C15.7194 18.64 13.3994 16.32 13.3994 13.44C13.3994 10.56 15.7194 8.23999 18.5994 8.23999C21.4794 8.23999 23.7994 10.56 23.7994 13.44C23.7194 16.32 21.3994 18.64 18.5994 18.64ZM18.5994 9.91999C16.5994 9.91999 14.9994 11.52 14.9994 13.52C14.9994 15.52 16.5994 17.12 18.5994 17.12C20.5994 17.12 22.1994 15.52 22.1994 13.52C22.1994 11.52 20.5994 9.91999 18.5994 9.91999Z" fill="#5E0000"/>
    <path d="M18.5999 42.72C18.1999 42.72 17.8799 42.48 17.7999 42.08C16.7599 35.44 13.5599 28.32 8.19994 20.96C6.59994 18.72 5.71994 16.08 5.79994 13.36C5.87994 6.39999 11.5599 0.799988 18.5199 0.799988C18.5999 0.799988 18.5999 0.799988 18.6799 0.799988C22.0399 0.799988 25.2399 2.15999 27.5599 4.55999C29.9599 6.95999 31.2399 10.16 31.2399 13.52C31.2399 16.16 30.4399 18.64 28.9199 20.8C23.4799 28.48 20.2799 35.68 19.3199 42.08C19.3199 42.4 18.9999 42.72 18.5999 42.72ZM18.5999 2.31999C12.5199 2.31999 7.47994 7.19999 7.39994 13.36C7.39994 15.76 8.11994 18.08 9.47994 20.08C14.0399 26.32 17.0799 32.4 18.5999 38.16C20.1199 32.48 23.1599 26.32 27.7199 19.84C28.9999 17.92 29.7199 15.76 29.7199 13.44C29.7199 10.48 28.5999 7.67999 26.5199 5.59999C24.4399 3.51999 21.6399 2.31999 18.6799 2.31999H18.5999Z" fill="#5E0000"/>
    <path d="M25 41.44C24.92 41.44 24.84 41.44 24.76 41.44C23.96 41.2 23 41.04 21.96 40.88C21.56 40.8 21.24 40.48 21.32 40C21.4 39.6 21.72 39.28 22.2 39.36C23.4 39.52 24.44 39.68 25.24 39.92C25.64 40.08 25.88 40.48 25.8 40.88C25.64 41.2 25.32 41.44 25 41.44Z" fill="#5E0000"/>
    <path d="M18.5992 45.2C15.0792 45.2 9.15918 44.56 9.15918 42.16C9.15918 41.36 9.79918 40.64 11.1592 40.16C12.1992 39.76 13.6392 39.44 15.2392 39.28C15.6392 39.2 16.0392 39.52 16.1192 40C16.1992 40.4 15.8792 40.8 15.3992 40.88C12.2792 41.2 10.9192 41.92 10.6792 42.24C10.8392 42.4 11.3992 42.8 12.9192 43.2C14.4392 43.6 16.4392 43.76 18.5192 43.76C21.3192 43.76 23.8792 43.44 25.3992 42.8C25.7992 42.64 26.2792 42.8 26.4392 43.2C26.5992 43.6 26.4392 44.08 26.0392 44.24C24.2792 44.8 21.5592 45.2 18.5992 45.2Z" fill="#5E0000"/>
    <path d="M25.3199 37.68H25.2399C24.3599 37.52 23.4799 37.44 22.5999 37.36C22.1999 37.36 21.8799 36.96 21.8799 36.56C21.8799 36.16 22.2799 35.84 22.6799 35.84C23.6399 35.92 24.5199 36 25.4799 36.16C25.8799 36.24 26.1999 36.64 26.1199 37.04C26.0399 37.44 25.6399 37.68 25.3199 37.68Z" fill="#5E0000"/>
    <path d="M18.5995 49.68C13.8795 49.68 9.47953 49.04 6.11953 47.84C2.51953 46.56 0.519531 44.72 0.519531 42.72C0.519531 38.8 7.55953 36.48 14.5195 35.92C14.9195 35.92 15.3195 36.16 15.3195 36.64C15.3195 37.04 15.0795 37.44 14.5995 37.44C10.9195 37.76 7.63953 38.48 5.31953 39.52C3.15953 40.48 1.95953 41.6 1.95953 42.72C1.95953 45.28 8.75953 48.16 18.4395 48.16C22.1195 48.16 25.5595 47.76 28.5195 46.96C28.9195 46.88 29.3195 47.12 29.4795 47.52C29.5595 47.92 29.3195 48.32 28.9195 48.48C25.9595 49.2 22.3595 49.68 18.5995 49.68Z" fill="#5E0000"/>
    <path d="M38.4394 51.2C38.2794 51.2 38.1194 51.2 38.0394 51.12L33.7994 48.48C29.7994 45.84 27.3994 41.6 27.3994 37.04V26.8C27.3994 26.56 27.5594 26.32 27.7194 26.16C27.9594 26 28.1994 26 28.4394 26.08L30.4394 26.96C32.2794 27.68 34.3594 27.68 36.1994 26.96L38.0394 26.08C38.2794 26 38.4394 26 38.6794 26.08L40.6794 26.96C42.5194 27.68 44.5994 27.68 46.4394 26.96L48.2794 26.08C48.5194 26 48.7594 26 48.9994 26.16C49.2394 26.32 49.3194 26.56 49.3194 26.8V37.04C49.3194 41.6 46.9194 45.92 42.9194 48.48L38.6794 51.12C38.6794 51.2 38.5994 51.2 38.4394 51.2ZM34.5994 47.2L38.3594 49.6L42.1194 47.2C45.7194 44.88 47.8794 41.04 47.8794 37.04V28L47.0794 28.32C44.8394 29.2 42.3594 29.2 40.1194 28.4L38.3594 27.6L36.7594 28.32C34.5194 29.2 32.0394 29.2 29.7994 28.4L28.8394 28V37.04C28.9194 41.04 31.0794 44.88 34.5994 47.2Z" fill="#5E0000"/>
    <path d="M36.3593 41.36C36.1193 41.36 35.9593 41.28 35.7993 41.12L33.4793 38.8C33.1593 38.48 33.1593 38 33.4793 37.68C33.7993 37.36 34.2793 37.36 34.5993 37.68L36.4393 39.52L42.7593 33.2C43.0793 32.88 43.5593 32.88 43.8793 33.2C44.1993 33.52 44.1993 34 43.8793 34.32L36.9993 41.2C36.7593 41.28 36.5993 41.36 36.3593 41.36Z" fill="#5E0000"/>
  </svg>

</template>

<script>
export default {
  name: "IconInsurance"
}
</script>

<style scoped>

</style>